<template>
  <div class="page-with-title">
    <page-title>
      <div class="d-flex">
        <div>
          <h1>Playlist {{ playlist.nome }}</h1>
          <a
            :href="playlist.url"
            target="_blank"
          >{{ playlist.url }}</a>
        </div>

        <div class="ml-4" v-if="isEditing">
          <IconMenuDropdown icon="fa fa-cog">
            <ItemDropdown
              @click="destroyPlaylist"
            >excluir playlist</ItemDropdown>
          </IconMenuDropdown>
        </div>
      </div>
    </page-title>
    
    <div class="p-4 mt-4">
      <div class="row align-items-center">
        <div class="col-2">
          <div class="embed-responsive embed-responsive-16by9">
            <img
              ref="avatar"
              :src="playlist.url_foto ? playlist.avatar : `/img/playlist-placeholder.jpg`"
              :alt="`Playlist ${playlist.nome}`"
              class="embed-responsive-item"
            >
            <UploadButton
              :fullmode="true"
              @change="$e => uploadImage($e)"
            />
          </div>
        </div>
        <div
          v-if="isEditing"
          class="col-1"
        >
          <div class="form-group">
            <label for="artista">ID</label>
            <input
              v-model="playlist.id_playlist"
              type="text"
              class="form-control"
              readonly
            >
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="artista">Nome</label>
            <input
              v-model="playlist.nome"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-5">
          <div class="form-group">
            <label for="artista">Pesquisar música</label>

            <SongsSearchInput
              :songs="playlistSongs"
              @song-selected="selectSongTip"
            />
          </div>
        </div>
      </div>

      <SortableList
        :items="playlistSongs"
        item-key="id_musica"
        item-sorting-key="posicao"
        :max-positions="playlistSongs.length"
        ref="sortableList"
        @item-removed="playlistSongRemoved"
      >
        <template v-slot:default="{ item }">
          <b>{{ item.song.titulo }}</b> - {{ item.song.artista }}
        </template>
      </SortableList>

      <div class="mt-4 mb-3">
        <button
          :disabled="$v.$invalid"
          class="btn btn-2 btn-bigger mr-3"
          @click="submit"
        >
          <b><i class="fa fa-check" /> Salvar</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import {
    getPlaylist,
    storePlaylist,
    storePlaylistSongs,
    updatePlaylist,
    updatePlaylistSongs,
    deletePlaylist
  } from '@/api/playlists'
  import { validationMixin } from 'vuelidate'
  import playlistValidation from '@/validations/playlistValidation'
  import SongsSearchInput from '@/components/songs/SongsSearchInput'
  import SortableList from '@/components/utils/sorting/SortableList'
  import UploadButton from '@/components/utils/UploadButton'
  import IconMenuDropdown from '@/components/utils/dropdown/IconMenuDropdown'
  import ItemDropdown from '@/components/utils/dropdown/ItemDropdown'

  export default {
    components: {
      PageTitle,
      SongsSearchInput,
      SortableList,
      UploadButton,
      IconMenuDropdown,
      ItemDropdown,
    },

    mixins: [
      validationMixin
    ],

    validations: playlistValidation,

    data: () => ({
      playlist: {
        id_playlist: null,
        avatar: null,
        novo_avatar: null
      },

      playlistSongs: [],

      playlistSongsRemoved: [],
    }),

    computed: {
      isEditing() {
        return !!this.$route.params.id
      } 
    },

    async beforeRouteEnter(to, from, next) {
      next(async (self) => {
        if (self.isEditing) {
          self.playlist = await self.fetchPlaylist()
          self.playlistSongs = self.playlist.playlist_songs

          self.$nextTick(() => {
            self.$refs.sortableList.reorderitems()
          })
        }
      })
    },

    methods: {
      uploadImage ($e) {
        const file = $e.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
          this.$refs.avatar.src = reader.result
          this.playlist.novo_avatar = file
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      },

      selectSongTip (song) {
        this.playlistSongs.push({
          id_musica: song.id_musica,
          posicao: this.playlistSongs.length + 1,
          song
        })
      },

      async fetchPlaylist () {
        return await getPlaylist(this.$route.params.id)
      },

      async submit () {
        if (this.isEditing) {
          await updatePlaylist(this.playlist)
          await updatePlaylistSongs(
            this.playlist.id_playlist,
            this.playlistSongs,
            this.playlistSongsRemoved
          )
        } else {
          const playlist = await storePlaylist(this.playlist)
          await storePlaylistSongs(playlist.id_playlist, this.playlistSongs)

          this.$router.push({
            name: 'playlistShow',
            params: {id: playlist.id_playlist}
          })
        }
      },

      playlistSongRemoved (playlistSong) {
        if (playlistSong.id_playlist) {
          this.playlistSongsRemoved.push(playlistSong.id_playlist)
        }
      },

      async destroyPlaylist () {
        if (!window.confirm('Tem certeza que deseja EXCLUIR essa playlist?')) {
          return null
        }
        
        await deletePlaylist(this.playlist.id_playlist)
        this.$router.replace({ name: 'playlists.index' })
      }
    },
  }
</script>
