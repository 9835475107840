const { required } = require('vuelidate/lib/validators')

export default {
  playlist: {
    nome: {
      required
    }
  },
  playlistSongs: {
    required
  }
}